<template>
  <div>
    <Breadcrumbs title="Users" />
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">

              <b-row>
                <b-col cols="12" lg="6" class="m-t-10">
                  <b-input-group>
                    <b-form-input v-model="filter" placeholder="Type to Search" class="border btn-square" autofocus></b-form-input>
                    <b-input-group-append>
                      <b-button :disabled="!filter" @click="filter = ''" class="btn btn-square border" variant="primary">Clear</b-button>
                    </b-input-group-append>
                    <b-input-group-append>
                      <router-link v-show="checkUserPermissions(user,['create_users'])" :to="{ name: 'users-create' }" class="btn btn-square btn-success border">New User</router-link>
                    </b-input-group-append>
                    <b-input-group-append>
<!--                      <b-select class="border-tab btn-square" v-model="record_status" :options="statusOptions" outlined></b-select>-->
                      <b-button v-for="(statOpt, index) in statusOptions" :key="index" :variant="statOpt.variant" v-on:click="loadByStatus(statOpt.value)">{{statOpt.text}}</b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-col>
                <b-col cols="12" lg="6" class="m-t-10 text-md-right">
                  <b-form-group label-cols="9" label="Per Page">
                    <b-form-select class="btn-square border text-center" v-model="perPage" :options="pageOptions"></b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>

              <div class="table-responsive datatable-vue">
                <b-table
                    ref="table"
                    show-empty
                    selected-variant="success"
                    :items="tableRecords"
                    :fields="tableFields"
                    :filter="filter"
                    :current-page="1"
                    :per-page="perPage"
                    @filtered="onFiltered"
                    @row-selected="rowSelected"
                >
                  <template #cell(action)="row" class="text-right">
                    <b-button v-show="(checkUserPermissions(user,['approve_users']) && row.item.status===1)" @click.prevent="showApproveModal(row.index, row.item, $event.target)" class="btn-square mr-1" variant="danger">Approve</b-button>
                    <router-link :to="{ name: 'users-view', params: { userId: row.item.id } }" class="btn btn-square btn-primary mr-1">View</router-link>
                    <router-link v-show="checkUserPermissions(user,['update_users'])" :to="{ name: 'users-manage', params: { userId: row.item.id } }" class="btn btn-square btn-success mr-1">Edit</router-link>
                    <b-button v-if="(row.item.status===2 || !!row.item.deleted_at)" v-show="checkUserPermissions(user,['create_users'])" class="btn-square" variant="warning" @click="showRestoreModal(row.index, row.item, $event.target)">Restore</b-button>
                    <b-button v-else v-show="checkUserPermissions(user,['create_users'])" class="btn-square" variant="danger" @click="showDeleteModal(row.index, row.item, $event.target)">Remove</b-button>
                  </template>
                </b-table>
              </div>


              <b-row >
                <b-col cols="4">
                  Records: <strong>{{ totalRows }}</strong>
                </b-col>
                <b-col cols="8" class="my-1" v-if="totalRows">
                  <b-pagination
                      align="right"
                      class="my-0"
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                      pills
                  ></b-pagination>
                </b-col>
              </b-row>





              <!-- Begin Delete Modal -->
              <b-modal id="delete_modal" title="Delete" cancel-variant="default" ok-variant="danger" ok-title="Confirm" @hide="clearFormModals" @ok="submitDeleteModal">
                <h6 class="m-b-10">Please confirm removal of following user:</h6>
                <table class="table table-sm table-bordernone">
                  <tr><td><strong>NIC: </strong></td><td>{{selected.nic}}</td></tr>
                  <tr><td><strong>Username: </strong></td><td>{{selected.username}}</td></tr>
                  <tr><td><strong>Full Name: </strong></td><td>{{selected.first_name}} {{selected.last_name}}</td></tr>
                </table>
              </b-modal>
              <!-- End Delete Modal -->

              <!-- Begin Approve Modal -->
              <b-modal id="approve_modal" title="Delete" cancel-variant="default" ok-variant="danger" ok-title="Approve" @hide="clearFormModals" @ok="submitApproveModal">
                <h6 class="m-b-10">Please confirm Approve of following user:</h6>
                <table class="table table-sm table-bordernone">
                  <tr><td><strong>NIC: </strong></td><td>{{selected.nic}}</td></tr>
                  <tr><td><strong>Username: </strong></td><td>{{selected.username}}</td></tr>
                  <tr><td><strong>Full Name: </strong></td><td>{{selected.first_name}} {{selected.last_name}}</td></tr>
                </table>
              </b-modal>
              <!-- End Approve Modal -->

              <!-- Begin Restore Modal -->
              <b-modal id="restore_modal" title="Restore" cancel-variant="default" ok-variant="warning" ok-title="Restore" @hide="clearFormModals" @ok="submitRestoreModal">
                <h6 class="m-b-10">Please confirm Restoring of following user:</h6>
                <table class="table table-sm table-bordernone">
                  <tr><td><strong>NIC: </strong></td><td>{{selected.nic}}</td></tr>
                  <tr><td><strong>Username: </strong></td><td>{{selected.username}}</td></tr>
                  <tr><td><strong>Full Name: </strong></td><td>{{selected.first_name}} {{selected.last_name}}</td></tr>
                </table>
              </b-modal>
              <!-- End Restore Modal -->



            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {checkUserPermissions} from '@/plugins/functions'
import CRUD_API from '@/services/crud'
import {mapGetters, mapState} from 'vuex'
export default {
  name: "users-index",
  computed: {
    ...mapState({ user: state => JSON.parse(state.auth.user) }),
    ...mapGetters({
      institutes: 'select/institutes'
    })
  },
  props: ['has_roles'],
  data() {
    return {
      filter: '',
      totalRows: 0,
      perPage: 5,
      currentPage: 1,
      pageOptions: [5,10,25,50,100],
      tableRecords: [],
      selected: [],

      api_base: '/api/backend/pages/users',
      tableFields: [
        { key: 'id', label: 'ID', sortable: false },
        { key: 'nic', label: 'NIC', sortable: true },
        { key: 'username', label: 'Username', sortable: true },
        { key: 'first_name', label: 'First Name', sortable: true },
        { key: 'last_name', label: 'Last Name', sortable: true },
        { key: 'nic', label: 'NIC/Passport', sortable: true },
        { key: 'phone_mobile', label: 'Mobile Number', sortable: true },
        { key: 'action', label: 'Action', sortable: false, class: 'text-right text-capitalize' }
      ],

      record_status: 0,
      statusOptions: [
        { value: -1, text: 'All', variant: 'light' },
        { value: 0, text: 'Active', variant: 'success' },
        { value: 1, text: 'Pending', variant: 'secondary' },
        { value: 2, text: 'Suspended', variant: 'danger' },
      ],

      // Form Modal
      form_modal_id: '',
      form_modal_title: '',
      form_modal_ok_variant: 'primary',
      form_modal_ok_title: '',
      formModal: {
        id: null,
        nic: null,
        username: null,
        first_name: null,
        last_name: null,
        email: null,
        phone_mobile: null,
        institute_id: null,
        capacity: 1,
        status: 0
      },

    }
  },
  watch: {
    currentPage() {
      this.fetchData()
    },
    perPage() {
      this.currentPage = 1
      this.fetchData()
    },
    filter() {
      this.record_status = -1
      this.currentPage = 1
      this.$store.dispatch('')
      this.fetchData()
    },
    has_roles() {
      this.currentPage = 1
      this.fetchData()
    },
    record_status() {
      this.currentPage = 1
      this.fetchData()
    }
  },
  mounted() {
    this.record_status = 0;
    if (this.has_roles.includes('student')) {
      this.record_status = 1;
    }
    this.fetchData()
  },
  created() {
    this.$store.dispatch('select/fetchRecords', { id: 'institutes' })
  },
  methods: {
    checkUserPermissions,
    updateFilter(value) {
      this.filter = value
    },
    onFiltered() {//filteredItems
      this.currentPage = 1
      // this.items.count = filteredItems.length
    },
    rowSelected(item) {
      this.selected = item
    },
    clearSearch() {
      this.filter = ''
      this.currentPage = 1
      this.fetchData()
    },
    handlePageChange(value) {
      //alert(value)
      this.perPage = value
      this.fetchData()
    },
    handlePageSizeChange(value) {
      this.currentPage = value
      this.fetchData()
    },
    async fetchData() {
      this.tableRecords = []
      await CRUD_API.index( this.api_base, {
        url: this.api_base,
        params: {
          per_page: this.perPage,
          page: this.currentPage,
          filter: this.filter,
          has_roles: this.has_roles,
          status: this.record_status
        }
      }).then(function(resp) {
        this.totalRows = resp.data.data.total
        this.tableRecords = resp.data.data.data
      }.bind(this)).catch((err) => {
        console.log(err)
        this.$toasted.error("Error occurred while retrieving data")
      }).finally(() => {
        this.$refs.table.refresh()
      })
    },

    clearFormModals() {
      this.formModal.id = null
      this.formModal.index = null
      this.formModal.name = null
      this.formModal.capacity = 1
      this.formModal.status = 0
      this.formModal.institute_id = null
    },
    // Create Record Modal
    showCreateModal(button) {
      this.selected = { id: null, name: null, institute_id: null, capacity: 1, status: 0 }
      this.form_modal_title = "New User"
      this.form_modal_ok_title = 'Submit'
      this.$root.$emit('bv::show::modal', 'form_modal', button)
    },
    submitFormModal() {
      if (this.selected.id) {
        this.submitEditModal()
      } else {
        this.submitCreateModal()
      }
    },
    submitCreateModal() {
      CRUD_API.create(this.api_base, this.formModal)
          .then((resp) => {
            console.log(resp)
            this.$toasted.success("User added successfully")
          })
          .catch((err) => {
            console.log(err)
            this.$toasted.error("Error occurred while saving data")
          })
          .finally(() => {
            this.fetchData()
          })
    },
    // Edit Record Modal
    showEditModal(index, item, button) {
      this.selected = item
      this.form_modal_title = "Edit User"
      this.form_modal_ok_title = 'Update'
      this.$root.$emit('bv::show::modal', 'form_modal', button)
    },
    submitEditModal(){
      CRUD_API.update(this.api_base, this.selected.id, this.selected)
          .then((resp) => {
            console.log(resp)
            this.$toasted.success("User updated successfully")
          })
          .catch((err) => {
            console.log(err)
            this.$toasted.error("Error occurred while updating data")
          })
          .finally(() => {
            this.fetchData()
          })
    },
    // Delete Record Modal
    showDeleteModal(index, item, button) {
      this.selected = item
      this.$root.$emit('bv::show::modal', 'delete_modal', button)
    },
    submitDeleteModal(){
      CRUD_API.create('/api/backend/pages/user-suspend', { user_id: this.selected.id })
          .then(() => {
            this.$toasted.success("User suspended successfully")
          })
          .catch((error) => {
            console.log(error)
            this.$toasted.error("Error occurred while suspending user")
          })
          .finally(() => {
            this.fetchData()
          })
      // CRUD_API.delete(this.api_base, this.selected.id)
      //     .then((resp) => {
      //       console.log(resp)
      //       this.$toasted.success("User removed successfully")
      //     })
      //     .catch((err) => {
      //       console.log(err)
      //       this.$toasted.error("Error occurred while removing data")
      //     })
      //     .finally(() => {
      //       this.fetchData()
      //     })
    },

    showApproveModal(index, item, button) {
      this.selected = item
      this.$root.$emit('bv::show::modal', 'approve_modal', button)
    },
    async submitApproveModal() {
      CRUD_API.create('/api/backend/pages/user-approve', { user_id: this.selected.id })
          .then(() => {
            this.$toasted.success("User approved successfully")
          })
          .catch((error) => {
            console.log(error)
            this.$toasted.error("Error occurred while approving user")
          })
          .finally(() => {
            this.fetchData()
          })
    },

    showRestoreModal(index, item, button) {
      this.selected = item
      this.$root.$emit('bv::show::modal', 'restore_modal', button)
    },
    async submitRestoreModal() {
      CRUD_API.create('/api/backend/pages/user-restore', { user_id: this.selected.id })
          .then(() => {
            this.$toasted.success("User restored successfully")
          })
          .catch((error) => {
            console.log(error)
            this.$toasted.error("Error occurred while restoring user")
          })
          .finally(() => {
            this.fetchData()
          })
    },

    loadByStatus(status) {
      this.record_status = status
    }
  }
}
</script>

<style scoped>

</style>
